<template>
  <v-btn @click="back" v-if="create">
    <span>{{'action.back'| t}}</span>
    <v-icon>fa fa-arrow-left</v-icon>
  </v-btn>
</template>

<script>
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  computed: {
    create() {
      if(!this.dataAction) return true
      if(this.dataAction.back === false) return false
      return true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
